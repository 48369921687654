import * as React from 'react'

import SectionIntro from './Section.intro'
import SectionSimulate from './Section.simulate'
import SectionAbout from './Section.about'
import SectionEconomy from './Section.economy'
import SectionCompanies from './Section.companies'
import SectionQuestions from './Section.questions'

const View: React.FC = () => (
  <>
    <SectionIntro />
    <SectionSimulate />
    <SectionAbout />
    <SectionEconomy />
    <SectionCompanies />
    <SectionQuestions />
  </>
)

export default View
