import React, { ReactNode } from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Title, Text } from '@superlogica/design-ui'

import { ActionList } from 'components/helpers'
import * as S from './Hero.styled'

export interface HeroProps {
  appearance?: 'box' | 'full'
  badge?: string
  badgeColor?: Color
  color?: string
  title: string
  subtitle?: string
  body?: string
  actions?: UrlProps[]
  image: ReactNode
}

const Hero: React.FC<HeroProps> = ({
  appearance = 'box',
  color = 'sonic',
  badge,
  badgeColor,
  title,
  subtitle,
  body,
  actions = [],
  image
}) => {
  return (
    <S.Wrapper appearance={appearance} color={color}>
      <Container>
        <S.InnerWrapper>
          <S.Content>
            {!!badge && (
              <Text
                color={badgeColor || `${color}500`}
                weight="bold"
                size="body02"
                letterSpacing={1}
                uppercase
                marginBottom={16}
              >
                {badge}
              </Text>
            )}
            <Title
              color="trooper900"
              font="heading"
              size="display01"
              marginBottom={16}
            >
              {title}
            </Title>
            {!!subtitle && (
              <Text
                size="body03"
                color="trooper700"
                marginBottom={24}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
            {!!body && (
              <Text
                color="trooper700"
                secondColor={
                  appearance === 'box' ? 'trooper900' : `${color}900`
                }
                dangerouslySetInnerHTML={{ __html: `${body}` }}
              />
            )}
            {!!actions && (
              <ActionList
                marginTop={40}
                color={`${color}500` as Color}
                items={actions}
              />
            )}
          </S.Content>
          <S.Figure>{image}</S.Figure>
        </S.InnerWrapper>
      </Container>
    </S.Wrapper>
  )
}

export default Hero
