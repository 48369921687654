import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Text, Title } from '@superlogica/design-ui'
import { EconomyCalculator } from 'components/app'

import * as S from './styled'

const Section = () => (
  <S.SectionSimulate id="simular-economia">
    <Block tag="header">
      <Container>
        <Row justify="center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Title
              font="heading"
              size="display02"
              color="trooper900"
              textAlign="center"
              marginBottom={16}
            >
              Calcule quanto você vai economizar nos gastos com energia em um
              ano
            </Title>
            <Text color="trooper700" textAlign="center">
              De acordo com a pesquisa da Associação Brasileira dos
              Comercializadores de Energia, 84% dos brasileiros consideram a
              energia cara ou muito cara.
            </Text>
          </Col>
        </Row>
      </Container>
    </Block>
    <Block tag="article">
      <EconomyCalculator />
    </Block>
  </S.SectionSimulate>
)

export default Section
