import React, { useContext, useRef } from 'react'
import cn from 'classnames'
import { useIntersection } from 'react-use'
import { Vector, parseColor, Text } from '@superlogica/design-ui'
import { ThemeContext } from 'styled-components'
import { rgba } from 'polished'

import * as S from './StepOverdue.styled'
import { VectorsProps } from 'assets/svg/vectors'

interface StepOverdueProps {
  data: {
    color: Color
    title: string
    icon?: VectorsProps
  }[]
  color?: string
}

const StepOverdue: React.FC<StepOverdueProps> = ({
  data,
  color: colorProp = 'sonic'
}) => {
  const themeContext = useContext(ThemeContext)

  const ref = useRef(null)
  const intersection = useIntersection(ref, {
    root: null,
    threshold: 1
  })

  return (
    <S.Wrapper ref={ref}>
      <S.StepProgress
        className={cn({ animate: intersection?.intersectionRatio === 1 })}
      />
      <S.StepProgressIcon
        className={cn({ animate: intersection?.intersectionRatio === 1 })}
      >
        <Vector name="thunderbolt" width={22} height={22} color="babar0" />
      </S.StepProgressIcon>
      <S.Steps>
        {data.map(({ icon, title, color }, i) => (
          <S.Step key={i} color={`${colorProp}300`}>
            <S.Dot
              color={color}
              variants={{
                enter: {
                  borderColor: parseColor(themeContext.colors[color]),
                  backgroundColor: parseColor(themeContext.colors.babar0),
                  boxShadow: `0 0 0 ${rgba(
                    parseColor(themeContext.colors.trooper700),
                    0
                  )}`
                },
                animate: {
                  borderColor: parseColor(themeContext.colors[color]),
                  backgroundColor: parseColor(themeContext.colors[color]),
                  boxShadow: `0 0 12px ${rgba(
                    parseColor(themeContext.colors.trooper700),
                    0.25
                  )}`,
                  transition: {
                    delay: 1.3 * i
                  }
                }
              }}
              initial="enter"
              animate={
                intersection?.intersectionRatio === 1 ? 'animate' : 'enter'
              }
              exit="enter"
            >
              {icon && <Vector name={icon} width={22} height={22} />}
            </S.Dot>

            {title && (
              <S.Caption>
                {title && <S.Title color="babar800">{title}</S.Title>}
              </S.Caption>
            )}
          </S.Step>
        ))}
      </S.Steps>
    </S.Wrapper>
  )
}

export default StepOverdue
