import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Title, Text, Button, Block, Vector } from '@superlogica/design-ui'
import { CheckList } from 'components/app'

import { useConfig } from 'hooks'
import * as S from './styled'

const SectionEconomy = () => {
  const { config } = useConfig()

  return (
    <S.SectionEconomy>
      <Container>
        <Row align="center" justify="space-between">
          <Col md={6}>
            <Block
              tag="header"
              marginBottom={{ xxxs: 40, md: 0 }}
              marginRight={{ xxxs: 0, md: 48 }}
            >
              <Title
                color="trooper0"
                font="heading"
                size="display02"
                weight="medium"
                maxWidth="480px"
                marginBottom={16}
              >
                Economize e ajude o planeta ao mesmo tempo
              </Title>
              <Text color="trooper0" marginBottom={16}>
                O mercado de Geração Distribuída tem aumentando a cada ano.
              </Text>
              <Text color="trooper0" marginBottom={16}>
                Hoje, a energia solar corresponde a 8,1% da matriz energética
                brasileira, com cerca de 16,4 gigawatts (GW). A nível de
                comparação, a usina de Itaipu tem capacidade de 14 gigawatts
                (GW).
              </Text>
              <Text color="trooper0" marginBottom={16}>
                Segundo a ABSOLAR (Associação Brasileira de Energia Solar
                Fotovoltaica), no Brasil, 2022 encerrará próximo de 25 GW de
                capacidade instalada em energia solar, representando
                aproximadamente 90% de crescimento.
              </Text>
              <Text color="trooper0" marginBottom={32}>
                <b>Fonte:</b> ABSOLAR - Associação Brasileira de Energia Solar
                Fotovoltaica
              </Text>
              <Button
                tag="a"
                title="Quero desconto!"
                color="tails500"
                weight="semiBold"
                href={config.checkout}
              >
                Quero desconto!
              </Button>
            </Block>
          </Col>
          <Col md={6}>
            <Block borderRadius={4} overflow="hidden">
              <Block
                padding={{ xxxs: 32, xs: 40 }}
                bgColor="tails500"
                borderRight="60px solid"
                borderColor="tails300"
              >
                <CheckList
                  color="babar0"
                  fontSize="checklist"
                  items={[
                    { label: 'Economia', vector: 'plus' },
                    { label: 'Conveniência', vector: 'plus' },
                    { label: 'Sustentabilidade', vector: 'plus' },
                    { label: 'Emissão de carbono', vector: 'less' }
                  ]}
                />
              </Block>
              <Block
                display="flex"
                padding={{ xxxs: 32, xs: 40 }}
                bgColor="trooper0"
                flexDirection={{ xxxs: 'column', xs: 'row' }}
              >
                <Vector
                  color="tails500"
                  name="light-bulb-shine-1"
                  width={40}
                  height={40}
                  marginRight={24}
                  marginBottom={{ xxxs: 24, xs: 0 }}
                />
                <Text color="trooper700">
                  A <b>Superlógica</b> se uniu à grandes empresas do setor de
                  Geração Distribuída com o objetivo de trazer uma alternativa
                  barata e sustentável aos condôminos, empoderando o consumidor
                  que, com ela, pode escolher sua comercializadora de energia e
                  simplificar o pagamento.
                </Text>
              </Block>
            </Block>
          </Col>
        </Row>
      </Container>
    </S.SectionEconomy>
  )
}

export default SectionEconomy
