import styled from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'
import { ConversionSectionProps } from './ConversionSection'

type InnerWrapperProps = Required<Pick<ConversionSectionProps, 'color'>>

export const Header = styled.header`
  position: relative;
  padding: 0 32px;

  ${media.greaterThan('md')} {
    padding: 0 60px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      height: 100vw;
    }

    &:after {
      height: 100%;
    }

    ${({ theme: { colors } }) => `
      &:before {
        background-color: ${parseColor(colors.charmander300)};
      }

      &:after {
        background-color: ${parseColor(colors.babar0)};
      }
  `}
  }

  ${media.lessThan('sm')} {
    margin-bottom: 40px;
  }
`

export const InnerWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop)
})<InnerWrapperProps>`
  position: relative;
  z-index: 10;

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: -40px;
    width: 100vw;
    height: 40px;
    display: block;
    z-index: 2;
  }

  ${({ theme: { colors }, color }) =>
    !!color &&
    `
    &:before {
      left: -100vw;
      background-color: ${parseColor(colors[color])};
    }

    &:after {
      left: 0;
      background-color: ${parseColor(colors.charmander500)};
    }
  `};

  ${media.lessThan('xs')} {
    &:before {
      left: 0;
    }

    &:after {
      width: 80vw;
      right: 0;
      left: auto;
    }
  }
`

export const Wrapper = styled.section`
  padding: 40px 0;
  overflow: hidden;

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.charmander700)};
  `};
`
