import { Text, media, parseColor } from '@superlogica/design-ui'
import { motion } from 'framer-motion'
import { rem } from 'polished'
import styled, { keyframes, css } from 'styled-components'

const progressWidth = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`

const progressHeight = keyframes`
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
`

const StepProgressIconHorizontal = keyframes`
  from {
    left: 0%;
  }

  to {
    left: 100%;
  }
`

const StepProgressIconVertical = keyframes`
  from {
    top: 0%;
  }

  to {
    top: calc(100% - 40px);
  }
`

export const Dot = styled(motion.span)`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    flex-shrink: 0;
    margin: auto;
  }

  ${({ theme }) => `
    background-color: ${parseColor(theme.colors.babar0)};
    border: 8px solid ${parseColor(theme.colors.babar0)};

    &:after {
      width: 60px;
      height: 60px;
      background-color: ${parseColor(theme.colors.babar0)};
      z-index: -2;
    }
  `}
`

export const Title = styled(Text)`
  font-size: ${rem(14)};
  line-height: 1.2;
`

export const Caption = styled.div`
  position: absolute;
`

export const Step = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${Caption} {
    top: calc(100% + 20px);
    left: 0;
    width: 250px;
  }

  &:nth-child(1) {
    ${({ theme }) => `
    .vector {
        color: ${parseColor(theme.colors.tails900)};
      }
    `}
  }

  &:nth-child(4) {
    ${({ theme }) => `
    .vector {
        color: ${parseColor(theme.colors.babar0)};
      }
    `}
  }

  ${media.lessThan('sm')} {
    justify-content: flex-start;
    flex-direction: row;

    ${Caption} {
      top: 0;
      left: 76px;
      width: calc(100% - 76px);
    }

    ${Dot} {
      margin-right: 24px;
    }

    &:not(:last-of-type) {
      margin-bottom: 60px;
    }

    ${Title} {
      text-align: left;
    }
  }

  ${media.lessThan('xxxs')} {
    &:not(:last-of-type) {
      margin-bottom: 90px;
    }
  }
`

export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 100%;

  ${media.lessThan('sm')} {
    flex-direction: column;
  }
`

export const StepProgress = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 12px;
  width: 0;
  z-index: -1;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${parseColor(
      theme.colors.tails100
    )} -0.70%, ${parseColor(theme.colors.tails700)} 100%)`};
  border-radius: 12px;

  &.animate {
    animation: 4s ${progressWidth} linear forwards;
  }

  ${media.lessThan('sm')} {
    top: 0;
    transform: translateY(0);
    width: 12px;
    height: 0%;
    left: 14px;
    background: ${({ theme }) =>
      `linear-gradient(to bottom, ${parseColor(
        theme.colors.tails100
      )} -0.70%, ${parseColor(theme.colors.tails700)} 100%)`};

    &.animate {
      animation: 4s ${progressHeight} linear forwards;
    }
  }
`

export const StepProgressIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #ffa760;
  position: absolute;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  margin-left: -40px;

  &.animate {
    animation: 4s ${StepProgressIconHorizontal} linear forwards;
  }

  ${media.lessThan('sm')} {
    margin-left: unset;
    &.animate {
      animation: 4s ${StepProgressIconVertical} linear forwards;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    background-color: white;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 12px;
    width: 100%;
    z-index: -1;
  }

  ${media.lessThan('sm')} {
    margin-left: 0;

    &:before {
      width: 12px;
      height: 100%;
      left: 14px;
    }
  }
`
