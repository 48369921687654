import styled from 'styled-components'
import { Accordion } from 'components/common'
import { parseColor } from '@superlogica/design-ui'

export const Heading = styled(Accordion.Heading)`
  padding: 32px 24px 32px 0;

  .title {
    flex: 1 1;
    margin-right: 24px;
  }
`

export const Body = styled(Accordion.Body)`
  .sdl-content-reset {
    padding-bottom: 32px;

    ul {
      margin-top: 12px;
      list-style-type: square;
      list-style-position: inside;

      li {
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
      }
    }

    p {
      ${({ theme: { colors } }) => `
        color: ${parseColor(colors.trooper700)};
      `}
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    a {
      font-weight: 700;
    }
  }
`

export const Item = styled(Accordion.Item)`
  ${({ theme }) => `
    border-bottom: 1px solid ${parseColor(theme.colors.babar100)};
  `};
`

export const Wrapper = styled(Accordion.Wrapper)``
