import styled from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'
import { rgba } from 'polished'

export const PlayButton = styled.span`
  margin-right: 60px;
  transition: all ease 400ms;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 24px;

  ${({ theme: { colors } }) => `
    border: 4px solid ${rgba(parseColor(colors.babar0), 0.5)};
  `}

  ${media.lessThan('sm')} {
    margin-bottom: 0;
    padding: 16px;
    width: 60px;
    height: 60px;
  }
`

export const Caption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 100%;
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
`

export const Poster = styled.figure`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 1;
  transition: all ease 1s;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
`

export const Wrapper = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  .player {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    iframe {
      border-radius: 8px;
      overflow: hidden;
    }

    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    ${PlayButton} {
      transform: scale(1.1);
    }
  }

  &.playing {
    ${Poster} {
      opacity: 0;
      visibility: hidden;
      user-select: none;
    }
  }
`
