import styled from 'styled-components'
import { parseColor } from '@superlogica/design-ui'
import { rgba } from 'polished'

import { CompanyProps } from './Company'

type WrapperProps = Required<Pick<CompanyProps, 'color'>>

export const Wrapper = styled.blockquote.withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop)
})<WrapperProps>`
  padding: 0 0 48px 32px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ theme: { colors }, color }) =>
    color &&
    `
      border-left: 1px solid ${rgba(parseColor(colors[color]), 0.2)};

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 48px;
        background-color: ${parseColor(colors[color])};
        position: absolute;
        top: 0;
        left: -1px;
      }
    `}
`
