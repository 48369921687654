import * as React from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'
import { Block, Title, Vector } from '@superlogica/design-ui'

import * as S from './styled'

const SectionCompanies = () => (
  <S.SectionCompanies>
    <Container>
      <S.InnerWrapper>
        <Row align="center">
          <Col lg={6}>
            <Block
              tag="header"
              marginBottom={{ xxxs: '32px', lg: '0' }}
              marginRight={{ xxxs: '0', lg: '60px' }}
            >
              <Title color="trooper900" size="display02" font="heading">
                Serviço garantido por alguns dos maiores especialistas em
                condomínios e energia limpa e renovável no Brasil.
              </Title>
            </Block>
          </Col>
          <Col lg={6}>
            <Block
              tag="article"
              display="grid"
              gridTemplateColumns={{
                xxxs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)'
              }}
              columnGap="32px"
              rowGap="32px"
            >
              <Block
                bgColor="babar0"
                borderRadius={4}
                borderBottom="4px solid"
                borderColor="tails500"
                padding={32}
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={150}
              >
                <Vector name="brand-color" width={171} height={26} />
              </Block>
              <Block
                bgColor="babar0"
                borderRadius={4}
                borderBottom="4px solid"
                borderColor="tails500"
                padding={32}
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={150}
              >
                <Vector name="brand-bowe" width={172} height={70} />
              </Block>
              <Block
                bgColor="babar0"
                borderRadius={4}
                borderBottom="4px solid"
                borderColor="tails500"
                padding={32}
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={150}
              >
                <Vector name="brand-comparta" width={172} height={70} />
              </Block>
              <Block
                bgColor="babar0"
                borderRadius={4}
                borderBottom="4px solid"
                borderColor="tails500"
                padding={32}
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={150}
              >
                <Vector name="brand-omega" width={172} height={70} />
              </Block>
            </Block>
          </Col>
        </Row>
      </S.InnerWrapper>
    </Container>
  </S.SectionCompanies>
)

export default SectionCompanies
