import styled from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

export const InnerWrapper = styled.div``

export const SectionSimulate = styled.section`
  padding: 80px 0;

  ${media.lessThan('sm')} {
    padding: 60px 0;
  }
`

export const SectionAbout = styled.section`
  overflow: hidden;
  position: relative;
  padding: 80px 0 170px 0;

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.trooper25)};
  `}

  ${InnerWrapper} {
    max-width: 85%;
  }

  ${media.lessThan('sm')} {
    padding: 60px 0 100px 0;
    ${InnerWrapper} {
      max-width: 100%;
    }
  }
`

export const SectionTax = styled.section`
  position: relative;
  padding-top: 80px;

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.babar50)};
    box-shadow: inset 0 -40px 0 ${parseColor(colors.charmander900)};
  `}

  ${media.lessThan('sm')} {
    padding-top: 60px;
  }
`

export const SectionEconomy = styled.section`
  padding: 80px 0;

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.tails900)};
  `};

  ${media.lessThan('sm')} {
    padding: 60px 0;
  }
`

export const SectionCompanies = styled.section`
  padding: 80px 0;

  ${InnerWrapper} {
    position: relative;
    &:before {
      content: '';
      height: 40px;
      width: 100vw;
      position: absolute;
      left: -100vw;
      bottom: -120px;
    }
  }

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.trooper50)};
    border-bottom: 40px solid ${parseColor(colors.tails300)};

    ${InnerWrapper} {
      &:before {
        background-color: ${parseColor(colors.tails700)};
      }
    }

  `};

  ${media.lessThan('sm')} {
    padding: 60px 0;

    border-width: 20px;

    ${InnerWrapper} {
      &:before {
        bottom: -80px;
        height: 20px;
      }
    }
  }
`

export const SectionQuestions = styled.section`
  padding: 80px 0 40px;

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.trooper25)};
  `};

  ${media.lessThan('sm')} {
    padding: 60px 0 20px;
  }
`
