import styled from 'styled-components'
import { rgba } from 'polished'
import { parseThemeColor } from 'utils'
import { Vector } from '@superlogica/design-ui'

import { FeatureCardProps } from './FeatureCard'

type WrapperProps = Pick<FeatureCardProps, 'color' | 'border'>

export const Icon = styled(Vector)``

export const Wrapper = styled.article.withConfig({
  shouldForwardProp: (prop) => !['color', 'border'].includes(prop)
})<WrapperProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 16px;

  ${({ theme, color }) =>
    !!color &&
    `
    ${Icon} {
      color: ${parseThemeColor(color, theme)};
    }
  `}

  ${({ theme, border, color }) =>
    !!border &&
    !!color &&
    `
    position: relative;
    padding-left: 32px;
    border-left: 1px solid ${rgba(parseThemeColor(color, theme), 0.3)};

    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 48px;
      background-color: ${parseThemeColor(color, theme)};
      position: absolute;
      top: 0;
      left: -1px;
    }
  `}
`
