import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { getTrackBackground, Range } from 'react-range'
import { Button, parseColor, Text } from '@superlogica/design-ui'
import { useTheme } from 'styled-components'

import { useConfig } from 'hooks'
import { companiesConfig as companies, EnergyCompany } from 'config'
import { formatCurrency } from 'utils'

import * as S from './EconomyCalculator.styled'

interface EconomyCalculator {
  min?: number
  max?: number
}

interface CalculatorState {
  company: EnergyCompany
  expense: number
  economy?: number
}

const DEFAULT_STATE: CalculatorState = {
  company: 'default',
  expense: 0
}

const MONTHS = 12

const EconomyCalculator: React.FC<EconomyCalculator> = ({
  min = 0,
  max = 1000
}) => {
  const [data, setData] = useState<CalculatorState>(DEFAULT_STATE)
  const { config, setConfig } = useConfig()
  const { colors } = useTheme()

  useEffect(() => {
    const { expense } = data
    const { discount } = companies[data.company]

    const totalPerYear = expense * MONTHS
    const economyPerYear = (totalPerYear / 100) * discount

    setData((prevState) => ({ ...prevState, economy: economyPerYear }))
  }, [data.expense, data.company])

  const checkoutURL = () => {
    if (config.checkout.includes('?')) {
      return encodeURI(`${config.checkout}&amount=${config.average_spend}`)
    }

    return encodeURI(`${config.checkout}?amount=${config.average_spend}`)
  }

  return (
    <S.Wrapper>
      <Container>
        <Row justify="center">
          <Col xs={12} sm={10} md={7} lg={6}>
            <S.Simulate>
              <Text
                textAlign="center"
                size="display04"
                color="trooper700"
                marginBottom={16}
                maxWidth={{ xxxs: 200, xs: '100%' }}
              >
                Qual seu gasto médio com energia elétrica:{' '}
                <S.Expense>{formatCurrency(data.expense)}</S.Expense>
              </Text>
              <S.Field>
                <Range
                  values={[data.expense]}
                  step={1}
                  min={min}
                  max={max}
                  onChange={(values) => {
                    setData({ ...data, expense: values[0] })
                  }}
                  onFinalChange={(values) =>
                    setConfig({ average_spend: values[0] })
                  }
                  renderTrack={({ props, children }) => {
                    const { ref: trackRef, onMouseDown, onTouchStart } = props

                    return (
                      <S.Track
                        onMouseDown={onMouseDown}
                        onTouchStart={onTouchStart}
                      >
                        <S.TrackBar
                          ref={trackRef}
                          style={{
                            background: getTrackBackground({
                              min,
                              max,
                              values: [data.expense],
                              colors: [
                                parseColor(colors.tails500),
                                parseColor(colors.trooper300)
                              ]
                            })
                          }}
                        >
                          {children}
                        </S.TrackBar>
                      </S.Track>
                    )
                  }}
                  renderThumb={({ props }) => <S.Thumb {...props} />}
                />
              </S.Field>
              {!!data.economy && (
                <Text
                  textAlign="center"
                  size="display02"
                  color="trooper700"
                  marginTop={32}
                >
                  Economia anual de:{' '}
                  <S.Expense>{formatCurrency(data.economy)}</S.Expense>
                </Text>
              )}
              <Button
                tag="a"
                href={checkoutURL()}
                color="tails500"
                weight="semiBold"
                size="lg"
                marginTop={48}
                title="Quero desconto!"
              >
                Quero desconto!
              </Button>
              <Text
                textAlign="center"
                size="label03"
                color="trooper700"
                maxWidth={310}
                marginTop={24}
              >
                Este valor é apenas uma simulação do desconto possível, o valor
                real pode sofrer variações.
              </Text>
            </S.Simulate>
          </Col>
        </Row>
      </Container>
    </S.Wrapper>
  )
}

export default EconomyCalculator
