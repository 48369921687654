import styled from 'styled-components'
import { rem, rgba } from 'polished'
import { media, parseColor } from '@superlogica/design-ui'

export const Label = styled.span`
  ${media.between('xs', 'sm')} {
    text-align: right;
  }
`

export const TableRow = styled.tr`
  ${media.lessThan('xs')} {
    &:not(.active) {
      display: none;
    }
  }
`

export const TableData = styled.td`
  transition: background-color 150ms ease-in-out;
  cursor: default;

  ${({ theme: { campaignColors, colors } }) => `
    border: 2px solid ${parseColor(colors.babar0)};
    background-color: ${campaignColors.blueTable100};
  `}

  ${media.lessThan('sm')} {
    display: flex;
    justify-content: space-between;

    &:not(:first-of-type) {
      padding-top: 8px;
    }

    &:not(:last-of-type) {
      padding-bottom: 8px;
    }

    &:before {
      content: attr(data-label);
      margin-right: 4px;
      font-weight: 700;
    }
  }

  ${media.lessThan('xxs')} {
    flex-direction: column;
    &:before {
      display: block;
      margin-right: 0;
      margin-bottom: 4px;
    }
  }
`

export const TableBody = styled.tbody`
  vertical-align: inherit;

  ${({ theme: { colors } }) => `
    ${media.greaterThan('sm')} {
      ${TableRow} {
        &:hover {
          ${TableData} {
            background-color: ${parseColor(colors.charmander100)};
          }
        }
      }
    }
  `}
`

export const TableHead = styled.th`
  vertical-align: middle;
  text-align: -webkit-match-parent;
  font-weight: 600;

  ${({ theme: { colors, campaignColors } }) => `
    background-color: ${campaignColors.blueTable200};
    border: 2px solid ${parseColor(colors.babar0)};
  `}

  ${media.lessThan('sm')} {
    display: none;
  }
`

export const TableHeader = styled.thead``

export const Table = styled.table`
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  vertical-align: top;
  font-size: ${rem(10)};
  line-height: 1.2;
  text-align: center;
  border-collapse: collapse;

  > :not(caption) > * {
    border-width: 1px 0;
    border-collapse: collapse;
    background-image: none;
  }

  > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    border-bottom-width: 1px;
  }

  ${media.lessThan('sm')} {
    text-align: left;
  }
`

export const Wrapper = styled.div`
  ${media.greaterThan('xs')} {
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  }
`
