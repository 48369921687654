import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image'
import { Block } from '@superlogica/design-ui'
import { Hero } from 'components/app'
import { useResponsiveImage } from 'hooks'
interface ImagesDataProps {
  images: {
    nodes: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }[]
  }
}

const SectionIntro = () => {
  const {
    images: { nodes: imageData }
  } = useStaticQuery<ImagesDataProps>(graphql`
    {
      images: allFile(
        filter: {
          relativePath: { in: ["home/hero.png", "home/hero-mobile.png"] }
        }
        sort: { fields: name }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              quality: 90
            )
          }
        }
      }
    }
  `)

  const [heroDesktop, heroMobile] = imageData

  const heroData = useResponsiveImage({
    desktop: heroDesktop.childImageSharp.gatsbyImageData,
    mobile: heroMobile.childImageSharp.gatsbyImageData
  })

  return (
    <Block tag="section">
      <Hero
        appearance="full"
        color="tails"
        title="Economize até 16% na conta de energia"
        subtitle="Conte com energia <b>renovável, limpa e barata</b> sem taxa de adesão, fidelidade ou mensalidade."
        actions={[
          {
            label: 'Simular economia',
            href: '#simular-economia',
            color: 'tails500'
          }
        ]}
        image={
          <GatsbyImage
            image={heroData}
            objectFit="cover"
            objectPosition="top center"
            style={{ width: '100%', height: '100%' }}
            alt="Uma lâmpada acesa colocada sobre a terra, simbolizando energia limpa."
          />
        }
      />
    </Block>
  )
}
export default SectionIntro
