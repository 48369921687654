import styled from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'
import { rem } from 'polished'

export const Thumb = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.tails500)};
    border: 1px solid ${parseColor(colors.babar100)};
    box-shadow: 0 0 0 10px ${parseColor(colors.babar0)},
                0 0 0 11px ${parseColor(colors.babar75)},
                0 0 8px 10px ${parseColor(colors.babar200)};
  `}
`

export const TrackBar = styled.div`
  width: 100%;
  height: 8px;
  align-self: center;
  border-radius: 4px;
`

export const Track = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  cursor: inherit;
  transform: scale(1);
`

export const Field = styled.div`
  width: 70%;
`

export const Expense = styled.span`
  font-weight: 700;
  font-variant-numeric: tabular-nums;

  ${media.lessThan('xs')} {
    display: block;
    margin-top: 8px;
  }
`

export const Simulate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;

  a {
    align-self: center;
  }
`

export const Link = styled.button`
  position: relative;
  z-index: 2;
  display: block;
  padding: 24px;
  border-bottom: 2.5px solid transparent;
  font-size: ${rem(14)};
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  white-space: nowrap;
  min-width: 180px;
  transition: all 300ms ease-in-out;

  ${({ theme: { colors } }) => `
    color: ${parseColor(colors.babar500)};

    &:hover {
      color: ${parseColor(colors.babar900)};
    }

    &.active {
      pointer-events: none;
      color: ${parseColor(colors.tails500)};
      border-color: ${parseColor(colors.tails500)};
    }
  `}
`

export const Item = styled.li`
  text-align: center;
`

export const List = styled.ul`
  display: flex;
  justify-content: center;
`

export const Companies = styled.nav`
  width: 100%;
  height: 72px;
  position: sticky;
  top: 0;
  z-index: 3;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:after {
    content: '';
    width: 100%;
    height: 2.5px;
    position: absolute;
    bottom: 0;
    display: block;
    z-index: 1;
  }

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.babar0)};

    &:after {
      background-color: ${parseColor(colors.babar100)};
    }
  `}
`

export const Wrapper = styled.div``
