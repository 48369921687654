import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Block, Title } from '@superlogica/design-ui'
import { Container } from 'react-awesome-styled-grid'

import { Faq } from 'components/app'

import * as S from './styled'

interface FaqData {
  allFaqJson: {
    nodes: {
      category: string
      title: string
      content: {
        title: string
        body: string
      }[]
    }[]
  }
}

const SectionFaq = () => {
  const {
    allFaqJson: { nodes: faqGroup }
  } = useStaticQuery<FaqData>(graphql`
    {
      allFaqJson(
        filter: { category: { in: ["cliente"] } }
        sort: { fields: order, order: ASC }
      ) {
        nodes {
          category
          title
          content {
            body
            title
          }
        }
      }
    }
  `)

  return (
    <S.SectionQuestions>
      <Container>
        <Block tag="header" marginBottom={8}>
          <Title
            color="trooper900"
            font="heading"
            size="display02"
            weight="medium"
            maxWidth="764px"
          >
            Ficou com alguma dúvida sobre as novas funcionalidades?{' '}
            <b>A gente te ajuda!</b>
          </Title>
        </Block>
        {faqGroup.map(({ category, title, content }) => (
          <Block tag="article" key={category}>
            {/* <Title
              color="charmander500"
              font="heading"
              size="display03"
              weight="medium"
            >
              {title}
            </Title> */}
            <Faq items={content} />
          </Block>
        ))}
      </Container>
    </S.SectionQuestions>
  )
}

export default SectionFaq
