import React, { useEffect } from 'react'
import { PageProps } from 'gatsby'

import { appConfig } from 'config'
import { Seo } from 'components/helpers'

import View from 'views/home'
import { useConfig } from 'hooks'

const IndexPage: React.FC<PageProps> = ({ location }) => {
  const { setConfig } = useConfig()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const cond = params.get('cond')

    if (cond) {
      setConfig({
        ...appConfig,
        checkout: `/checkout/?cond=${cond}`
      })
    } else {
      setConfig(appConfig)
    }
  }, [])

  return (
    <>
      <Seo
        title="Economize até 16% na conta de energia"
        description="Conte com uma energia renovável, limpa e barata, sem taxa de adesão, fidelidade ou mensalidade."
      />
      <View />
    </>
  )
}

export default IndexPage
