import styled, { css } from 'styled-components'

import { mapCSSPropsToStyled } from '@superlogica/design-ui'
import { VectorListProps } from './VectorList'

type WrapperProps = Pick<VectorListProps, 'columns'>

export const Content = styled.div`
  display: flex;
  align-items: center;
`

export const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Wrapper = styled.ul.withConfig({
  shouldForwardProp: (prop) => !['columns'].includes(prop as string)
})<WrapperProps>`
  ${({ columns }) => css`
    ${mapCSSPropsToStyled({ columns })}
  `}
`
