import styled from 'styled-components'
import { parseColor } from '@superlogica/design-ui'
import { rgba } from 'polished'
import { TileProps } from './Tile'

type WrapperProps = Required<Pick<TileProps, 'borderColor'>>

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['borderColor'].includes(prop)
})<WrapperProps>`
  padding: 4px 24px 16px 24px;
  height: 100%;
  position: relative;

  .title span {
    white-space: nowrap;
    display: block;
  }

  ${({ theme: { colors }, borderColor }) =>
    borderColor &&
    `
      border-left: 1px solid ${rgba(parseColor(colors[borderColor]), 0.3)};

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 48px;
        background-color: ${parseColor(colors[borderColor])};
        position: absolute;
        top: 0;
        left: -1px;
      }
    `}
`
