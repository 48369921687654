import styled, { css } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'
import { HeroProps } from './Hero'

type WrapperProps = Required<Pick<HeroProps, 'appearance' | 'color'>>

export const Figure = styled.figure`
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
  }

  ${media.lessThan('sm')} {
    position: relative;
  }
`

export const Content = styled.div``

export const InnerWrapper = styled.div`
  height: calc(100vh - 70px);
  min-height: 600px;
  max-height: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('sm')} {
    flex-direction: column;
    height: auto;
    min-height: auto;
  }
`

export const Wrapper = styled.article.withConfig({
  shouldForwardProp: (prop) => !['appearance', 'color'].includes(prop)
})<WrapperProps>`
  position: relative;
  overflow: hidden;

  ${({ theme: { colors }, appearance, color }) => {
    if (appearance === 'box') {
      return css`
        ${Content} {
          width: 45%;
          max-width: 460px;
        }

        ${Figure} {
          position: relative;
          width: 50%;
          height: 85%;
          max-height: 700px;
          right: 0;
          background-color: ${parseColor(colors[`${color}900` as Color])};
          border-right: 60px solid ${parseColor(colors[`${color}900` as Color])};

          > div {
            border-bottom-left-radius: 200px;
          }

          &:after {
            width: 20vw;
            height: 100%;
            right: calc(-20vw - 60px);
            background-color: ${parseColor(colors[`${color}500` as Color])};
          }
        }

        ${media.lessThan('sm')} {
          ${Figure} {
            > div {
              border-bottom-left-radius: 100px;
            }

            &:after {
              width: 30px;
              right: -60px;
            }
          }
        }
      `
    }

    if (appearance === 'full') {
      return css`
        ${Content} {
          max-width: 490px;
        }

        ${Figure} {
          position: absolute;
          z-index: -1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: ${parseColor(colors[`${color}900` as Color])};
          border-right: 120px solid
            ${parseColor(colors[`${color}500` as Color])};

          > div {
            border-bottom-right-radius: 200px;
          }

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0.95) 40%,
              rgba(255, 255, 255, 0) 70%
            );
            z-index: 1;
          }

          &:after {
            width: 120px;
            height: 80px;
            right: -120px;
            background-color: ${parseColor(colors[`${color}300` as Color])};
          }
        }

        ${media.lessThan('md')} {
          ${Content} {
            max-width: 500px;
          }
          ${Figure} {
            width: 100vw !important;
          }
        }

        ${media.lessThan('sm')} {
          ${Figure} {
            border-right-width: 32px;
            position: relative;
            top: auto;
            left: auto;

            > div {
              border-bottom-right-radius: 100px;
            }

            &:before {
              display: none;
            }

            &:after {
              width: 32px;
              right: -32px;
            }
          }
        }
      `
    }
  }}

  ${media.lessThan('sm')} {
    ${Content} {
      max-width: 100%;
      width: 100%;
      padding: 40px 0;
    }

    ${Figure} {
      width: 100%;
      height: 460px;
      left: 0;
      right: auto;
    }
  }

  ${media.lessThan('xs')} {
    ${Figure} {
      height: 360px;
    }
  }
`
