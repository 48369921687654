import styled, { css } from 'styled-components'

import { mapCSSPropsToStyled } from '@superlogica/design-ui'
import { ChecklistProps } from './CheckList'

type WrapperProps = Pick<ChecklistProps, 'columns'>

export const Item = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Wrapper = styled.ul.withConfig({
  shouldForwardProp: (prop) => !['columns'].includes(prop as string)
})<WrapperProps>`
  ${({ columns }) => css`
    ${mapCSSPropsToStyled({ columns })}
  `}
`
