import React, { useState } from 'react'
import { Title, ContentReset } from '@superlogica/design-ui'

import * as S from './Faq.styled'

interface FaqItem {
  title: string
  body: string
}
interface FaqProps {
  items: FaqItem[]
}

const Faq: React.FC<FaqProps> = ({ items = [] }) => {
  const [expanded, setExpanded] = useState<boolean | string>(false)

  return (
    <S.Wrapper expanded={expanded} setExpanded={setExpanded}>
      {items.map(({ title, body }, i) => (
        <S.Item key={`faq-${i}`} accordionId={`faq-${i}`}>
          <S.Heading chevron="trooper700">
            <Title tag="h4" size="display06" color="trooper900" weight="bold">
              {title}
            </Title>
          </S.Heading>
          <S.Body>
            <ContentReset body={body} />
          </S.Body>
        </S.Item>
      ))}
    </S.Wrapper>
  )
}

export default Faq
