import * as React from 'react'
import { VectorsProps } from 'assets/svg/vectors'

import * as S from './CheckList.styled'
import { Text, Vector } from '@superlogica/design-ui'

export interface ChecklistProps {
  items: { label: string; vector: VectorsProps }[]
  color?: Color
  fontSize?: FontSize
  columns?: number | Responsive<number>
}

const CheckList: React.FC<ChecklistProps> = ({
  items,
  color = 'trooper900',
  fontSize = 'body01',
  ...rest
}) => (
  <S.Wrapper {...rest}>
    {items.map(({ label, vector }, i) => (
      <S.Item key={`checklist-${i}`}>
        <Vector
          name={vector || 'check'}
          color={color}
          width={16}
          height={16}
          marginRight={16}
        />
        <Text size={fontSize} color={color}>
          {label}
        </Text>
      </S.Item>
    ))}
  </S.Wrapper>
)

export default CheckList
