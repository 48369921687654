import * as React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Text } from '@superlogica/design-ui'

import { StepOverdue } from 'components/app'

import * as S from './styled'

const SectionAbout = () => {
  return (
    <S.SectionAbout>
      <Container>
        <Block tag="header" maxWidth="680px" marginBottom={60}>
          <Title
            color="trooper900"
            size="display02"
            font="heading"
            marginBottom={16}
          >
            Como funciona?
          </Title>
          <Text color="trooper700" marginBottom={12}>
            Graças à Geração Distribuída, você recebe energia limpa e
            sustentável, sem precisar gastar com obras ou manutenção e, ainda,
            consegue reduzir seus gastos.
          </Text>
          <Text color="trooper700" weight="bold" size="body02">
            A Geração Distribuída é uma atividade legal, permitida pela
            resolução nº 482/2012 da ANEEL
          </Text>
        </Block>
        <Block tag="footer">
          <S.InnerWrapper>
            <StepOverdue
              data={[
                {
                  color: 'tails100',
                  icon: 'renewable-energy-solar-panel',
                  title:
                    'Nossa parceira conecta pequenos geradores que produzem energia renovável em todo Brasil.'
                },
                {
                  color: 'tails300',
                  title:
                    'A energia gerada é injetada na rede da sua distribuidora local (CPFL, CEMIG, ENEL etc).'
                },
                {
                  color: 'tails500',
                  title:
                    'Esse processo gera créditos com a distribuidora, que são abatidos do seu consumo mensal.'
                },
                {
                  color: 'tails700',
                  icon: 'lamp',
                  title:
                    'A distribuidora local continua fornecendo energia à sua residência, mas a cobrança agora será enviada pela Superlógica.'
                }
              ]}
            />
          </S.InnerWrapper>
        </Block>
      </Container>
    </S.SectionAbout>
  )
}

export default SectionAbout
